  <template>
  <!-- 注册背景 -->
  <div
    class="login-back"
    :style="{
      background:
        'url(' + require('@/assets/images/testlogin_back.gif') + ')' + 'no-repeat',
    }"
  >
    <!-- 注册logo -->
    <img class="logo" src="@/assets/images/login-logo.png" alt="" />
   <div class="login_outer">
    <div class="title">智能化多媒体管理和生产系统</div>
    <!-- 注册容器 -->
    <div class="login-container">
      <!-- 注册标题 -->
      <div class="login-or-register">
        <span class="re">注册</span>
      </div>
      <!-- 登录，注册表单 -->
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic"
        :rules="rules"
      >
        <!-- 邮箱 -->
        <el-form-item prop="email" :error="error">
          <el-input
          @keyup.enter.native="register('dynamicValidateForm')"
            type= "email"
            v-model="dynamicValidateForm.email"
            placeholder="邮 箱"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
          @keyup.enter.native="register('dynamicValidateForm')"
            type="password"
            v-model="dynamicValidateForm.password"
            placeholder="密 码"
          ></el-input>
        </el-form-item>
        <!-- 注册按钮 -->
        <el-form-item>
          <el-button
           class="test"
            style="width: 20.21vw;height: 4.3vh;"
            type="primary"
            @click="register('dynamicValidateForm')"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 忘记密码，没有账号 ?立即注册 -->
      <div class="bottom">
        <!-- 占位 -->
        <div></div>
        <!-- 已有账号，去登录 -->
        <div @click="goLogin" style="cursor: pointer">已有账号，去登录</div>
      </div>
    </div>
   </div>
  </div>
</template>
<style lang="scss" scoped>
//登录背景
.login-back {
 display: flex;
  flex-direction: column;
  align-items: flex-end;
   position:fixed;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background-size: cover !important;
  // 登录背景logo
  .logo {
    width: 11.5vw;
    height: 10.2vh;
    margin-top: 12vh;
    margin-right: 20.5vw;
  }
  .login_outer{
    margin-right: 9.9vw;
  }
  // 标题
  .title {
    font-size: 1.6vw;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 4px;
    text-align: center;
  }
  // 注册容器
  .login-container {
    margin-top: 6.9vh;
    width: 32.85vw;
    height: 47.6vh;
    background: rgba(57, 56, 70, 0.6);
    border-radius: 5px;
    //  注册
    .login-or-register {
      margin-left: 6.31vw;
      padding-top: 8.76vh;
      .re {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
      }
    }
    // 登录，注册表单
    .demo-dynamic {
    margin-top: 2.14vh;
    width: 20.21vw;
    margin-left: 6.1vw;
    }
    /deep/.el-input__inner {
      background: #393846;
      border-radius: 5px;
      opacity: 0.6;
      color: #fff;
    }
    /deep/.el-form-item__error {
       color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 30%;
      left: auto;
      right: -6vw;
      width: 5.9vw;
    }
    .test{
      /deep/span{
          padding-left:50px;
        letter-spacing: 50px;
      }
    }
    // 忘记密码，没有账号 ?立即注册
    .bottom {
     display: flex;
      width: 20.21vw;
      height: 4.3vh;
      margin-left: 6.3vw;
      justify-content: space-between;
    }
  }
}
</style>
<script>
import md5 from 'js-md5';
export default {
  name: "Login",
  data() {
    // 密码不能为纯数字的自定义验证规则
    var checkPassword = (rule, value, callback) => {
      if (/^\d+$/.test(value)) {
        return callback(new Error("密码不能纯数字"));
      }
      callback();
    };
    // 邮箱后缀仅为.com 跟.com.cn的自定义验证规则
    var checkEmail = (rule, value, callback) => {
      let end1=value.substr(value.length-3,3)
      let end2=value.substr(value.length-2,2)
      // 不符合规则提示错误信息
      if (!(end1=='com'||end2=='cn')) {
        return callback(new Error("请输入正确邮箱"));
      }
      callback();
    };
    return {
      // 表单数据对象
      dynamicValidateForm: {
        email:'',// "taiyang.yin@maixunbytes.com",
        password: '',//"y66666",
      },
      // 表单验证对象
      rules: {
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" },
          {
            type: "email",
            message: "请填写正确邮箱",
            trigger: "blur",
          },
          { validator: checkEmail, trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 6,
            message: "密码最少为6位",
            trigger: "blur",
          },
          { validator: checkPassword, trigger: "blur" },
        ],
      },
      // message的透明度
      opacity: 0,
      // 没有权限邮箱错误提示
      error:''
    };
  },
  methods: {
    // 注册按钮
    register(formName) {
      this.$refs[formName].validate((valid) => {
        // 通过验证在此发送注册请求
        if (valid) {
          let formData = new FormData();
          formData.append("email", this.dynamicValidateForm.email);
          formData.append("password", md5(this.dynamicValidateForm.password));
          this.$http({
            method: "POST",
            url: "accounts/register_user",
            data: formData,
          }).then((res) => {
            // console.log(res);
            // 注册成功跳转验证邮箱界面
            if (res.data.status == 0) {
              // 将邮箱跟密码做一下缓存，方便ValidateEmail页面进行使用
              sessionStorage.setItem('Email',this.dynamicValidateForm.email)
              sessionStorage.setItem('PassWord',this.dynamicValidateForm.password)
              this.$router.push({
                path: "/validatemail"
              });
            }
            // 重复注册
            else  {
              // 解决验证只显示第一次的bug
              this.error=''
              this.$nextTick(()=>{
                this.error=res.data.msg
              })
            }
          }).catch(error => console.log(error))
          // 没通过验证返回错误，不进行下一步
        } else {return false;}
      })
    },
    // 切换登录界面
    goLogin() {
      this.$router.push("/login");
    },

  },

};
</script>
